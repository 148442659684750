export default [
  {
    path: '/pokemontcg',
    children: [
      {
        path: '',
        name: 'pokenontcg',
        component: () => import('../modules/pokemontcg/PokemontcgView.vue')
      }
    ]
  }
]
